import React, {useRef} from 'react';
import {apiService} from "../../services/apiService";
import {useHistory, useParams} from 'react-router-dom';
import {ReactSVG} from 'react-svg';
import svgArrow from '../../sources/img/arrow-left.svg';
import KettellResultsChart from "./AdminMethodics/KettellResultsChart";
import {useReactToPrint} from 'react-to-print';
import {isAdmin} from '../../services/login.service';

function AdminUserTest() {
    const {userTestId, accessKey} = useParams();
    let history = useHistory();
    const [userTest, setUserTest] = React.useState([]);
    const [answerList, setAnswerList] = React.useState([]);
    const [isFetching, setFetching] = React.useState(false);
    const [changeStatusOpened, setChangeStatusOpened] = React.useState(false);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    React.useEffect(() => {
        setFetching(true);
        apiService.getUserTestForUser(userTestId, accessKey)
            .then(uTest => {
                setUserTest(uTest);
                apiService.getUserTestQuestionnaire(uTest.id).then(
                  response => {
                        Promise.all(
                            response.list.map(userAnswer => {
                                return apiService.getUserTestQuestionForUser(userAnswer.questionId)
                                    .then(q => {
                                        let question = null;
                                        if (q) {
                                            question = {
                                                ...q,
                                                answerOptions: JSON.parse(q.answerOptions),
                                                parameters: JSON.parse(q.parameters)
                                            }
                                        }
                                        return {...userAnswer, question: question};
                                    })
                            })
                        ).then(answerList => {
                            return answerList.filter(el => el.question !== null)
                        }).then(aList => {
                            setAnswerList(aList);
                        }).finally(() => setFetching(false));
                    }
                )
            });
    }, []);

    const deleteTest = () => {
        if (window.confirm('Вы уверены, что хотите удалить результаты опроса?')) {
            apiService.deleteUserTest(userTest).then(
                response => {
                    if (response.status === 'SUCCESS') {
                        history.push('/admin/user_testing');
                    }
                }
            );
        }
        apiService.deleteUserTest(userTest)
    };


    const sendEmail = () => {
        apiService.sendUserTestEmail(userTest).then(
            response => {
                alert(response.message);
            }, error => {
                alert(error);
            }
        )
    };

    const changeStatus = (newStatus) => {
        let nTest = {...userTest};
        delete nTest.answerDate;
        nTest.status = newStatus;

        apiService.updateUserTest(nTest, userTest.accessKey).then(
            response => {
                if(response.status === 'SUCCESS'){
                    window.location.reload();
                }else{
                    alert('Не удалось изменить статус!');
                }
            }, error=> {
                alert('Не удалось изменить статус!');
            }
        ).finally(() => setChangeStatusOpened(false));
    };

    return (
        <div className={'container'}>
            {userTest && <div className="d-flex justify-content-between mt-5">
                <div className="d-flex justify-content-between">
                    <button className={'btn btn-sm btn-orange-bordered mb-2 mr-3'}
                            onClick={() => history.push('/admin/user_testing')}>
                        <ReactSVG src={svgArrow}/>&#160;
                        Назад
                    </button>
                </div>
                <div className="d-flex justify-content-between">
                    <button className={'btn btn-orange-bordered mr-3'} onClick={() => sendEmail()}>Отправить ссылку
                        на тест
                    </button>
                    <button className={'btn btn-danger mr-3'} onClick={(e) => {
                        e.preventDefault();
                        deleteTest();
                    }}>Удалить результаты опроса
                    </button>
                    <button className={'btn btn-orange-bordered mr-3'} onClick={() => handlePrint()}>Распечатать
                    </button>
                </div>
            </div>}
            <div ref={componentRef}>
                <div className={'d-flex mt-5'}>
                    <h3>Опросный лист №{userTest.id}</h3>
                    <div className="btn-group" style={{marginLeft: '1rem'}}>
                        <button type="button" className={"btn btn-warning dropdown-toggle" + (changeStatusOpened ? ' show' : '')} onClick={() => setChangeStatusOpened(!changeStatusOpened)}>
                            {'Статус: ' + userTest.status}
                        </button>
                        <ul className={'dropdown-menu'  + (changeStatusOpened ? ' show' : '')}>
                            <li><a className="dropdown-item" onClick={() => changeStatus('Новый')}>Новый</a></li>
                            <li><a className="dropdown-item" onClick={() => changeStatus('Пройден')}>Пройден</a></li>
                            <li><a className="dropdown-item" onClick={() => changeStatus('Просмотрен')}>Просмотрен</a></li>
                        </ul>
                    </div>
                </div>

                {userTest &&
                <div className="alert alert-secondary mt-5" role="alert">
                    <p><strong>ФИО </strong>{userTest.lastName} {userTest.firstName} {userTest.middleName}</p>
                    <p><strong>email: </strong>{userTest.email}&#160;&#160;&#160;
                        <strong>Возраст: </strong>{userTest.age || '—'}&#160;&#160;&#160;
                        <strong>Пол: </strong> {userTest.gender || '—'}</p>
                </div>
                }
                {isFetching && <p>Подождите, идет загрузка ответов...</p>}
                {isAdmin() && userTest.methodology && userTest.methodology.includes('kettell-16') && !isFetching &&
                <KettellResultsChart userTest={userTest} answerList={answerList}/>
                }
            </div>
          {userTest.methodology && userTest.methodology.includes('standard') &&
            <h4 className={'mt-5'}>Правильных ответов {answerList.reduce((valueSum, a) => valueSum + a.answerValue, 0)} из {answerList.length}</h4>
          }

            {!isFetching && isAdmin() && answerList.length > 0 &&
            <table className="table table-hover mt-5">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Вопрос</th>
                    {userTest.methodology && userTest.methodology.includes('kettell-16') &&
                    <th scope="col">Шкала</th>}
                    <th scope="col">Ответ</th>
                  {userTest.methodology && userTest.methodology.includes('standard') ? <th scope="col">Результат</th> : <th scope="col">Баллы</th>}
                </tr>
                </thead>
                <tbody>
                {answerList.map((el, i) => {
                    return <tr className="col pointer" key={i}>
                        <td>{i + 1}</td>
                        <td>{el.question.question}</td>
                        {userTest.methodology && userTest.methodology.includes('kettell-16') &&
                        <td>{el.question.parameters.scale}</td>}
                        <td>{el.answer}</td>
                      <td>{userTest.methodology && userTest.methodology.includes('standard') ? <p className={'test_result ' + (el.answerValue === 1 ? 'correct' : 'wrong')}></p> : el.answerValue}</td>
                    </tr>;
                })}
                </tbody>
            </table>}
        </div>
    );
}

AdminUserTest.propTypes = {};

export default AdminUserTest;