import React from 'react';
import Modal from "../../components/Modal";
import {apiService} from "../../services/apiService";
import ClickOutside from "../../components/ClickOutside";
import PropTypes from 'prop-types';

function AdminGenerateUserTest({categoryList, onSuccess}) {
    const [isOpen, setOpen] = React.useState(false);
    const [userEmail, setUserEmail] = React.useState('');
    const [isDropDownOpen, setDropDownOpen] = React.useState(false);
    const [usedTests, setUsedTests] = React.useState([]);
    const [isFetching, setFetching] = React.useState(false);


    const generateQuestionare = () => {
        if (userEmail === '') {
            alert('Введите email опрашиваемого!');
        } else if (usedTests.length === 0) {
            alert('Выберите хотя бы один тест!');
        } else {
            setFetching(true);
            apiService.generateUserTest(userEmail, usedTests.map(el => {return el.id})).then(
                response => {
                    if (response.status === 'SUCCESS') {
                        onCancel();
                        onSuccess();
                    } else {
                        alert('Не удалось сгенерировать опросный лист!');
                    }
                }, () => alert('Не удалось сгенерировать опросный лист!')
            ).finally(() => setFetching(false));
        }
    };

    const onCancel = () => {
        setUserEmail('');
        setUsedTests([]);
        setOpen(false);
        setDropDownOpen(false);
    };

    const onSelectTest = (test) => {
        if (usedTests.findIndex(el => el.id === test.id) < 0) {
            setUsedTests([...usedTests, test]);
        } else {
            setUsedTests(usedTests.filter(el => el.id !== test.id));
        }
    };

    const onSelectSingleTest = (test) => {
        setUsedTests([test]);
        setDropDownOpen(false);
    };

    return (
        <>

            <button type="button" className="btn btn-orange-bordered ml-2" onClick={() => setOpen(true)}>
                Генерировать опросный лист
            </button>
            <Modal isClosable={true} title={'Создание опросного листа'} isOpen={isOpen}
                   isCustomBody={false}
                   onCancel={() => onCancel()} footer={false}>
                <div className="modal-body">
                    {!isFetching &&
                    <form>
                        <div className="form-group">
                            <label htmlFor="name"><b>Email опрашиваемого</b></label>
                            <input type="email" className="form-control" placeholder={'Например name@mail.ru'}
                                   onChange={(e) => setUserEmail(e.target.value)}
                                   value={userEmail}/>
                        </div>

                        {/*Select Multiple Test*/}
                        {/*<div className="form-group">*/}
                        {/*<label><b>Отметьте тесты, включенные в опрос</b></label>*/}
                        {/*<ul className={'tree'}>*/}
                        {/*{categoryList.map((cat, i) => {*/}
                        {/*return <SelectTest category={cat} key={i} usedTests={usedTests}*/}
                        {/*onSelectTest={(test) => onSelectTest(test)}/>;*/}
                        {/*})}*/}
                        {/*</ul>*/}
                        {/*</div>*/}

                        {/*DropBox with Multiple Select*/}
                        {/*<div className="btn-group" style={{width: '100%'}}>*/}
                            {/*<button type="button" className="btn btn-orange-bordered dropdown-toggle"*/}
                                    {/*onClick={() => setDropDownOpen(!isDropDownOpen)}>*/}
                                {/*Выбрать тесты*/}
                            {/*</button>*/}
                            {/*<ClickOutside onClick={() => setDropDownOpen(false)}>*/}
                                {/*<div className={'dropdown-menu' + (isDropDownOpen ? ' show' : '')}*/}
                                     {/*style={{width: '100%'}}>*/}
                                    {/*<ul className={'tree'}>*/}
                                        {/*{categoryList.map((cat, i) => {*/}
                                            {/*return <SelectTest category={cat} key={i} usedTests={usedTests} isMultiple={true}*/}
                                                               {/*onSelectTest={(test) => onSelectTest(test)}/>;*/}
                                        {/*})}*/}
                                    {/*</ul>*/}
                                {/*</div>*/}
                            {/*</ClickOutside>*/}
                        {/*</div>*/}

                        {/*DropBox with Single Select*/}
                        <div className="btn-group" style={{width: '100%'}}>
                            <button type="button" className="btn btn-orange-bordered dropdown-toggle"
                                    onClick={() => setDropDownOpen(!isDropDownOpen)}>
                                {usedTests.length === 0 ? 'Выбрать тесты' : usedTests[0].name}
                            </button>
                            <ClickOutside onClick={() => setDropDownOpen(false)}>
                                <div className={'dropdown-menu' + (isDropDownOpen ? ' show' : '')}
                                     style={{width: '100%'}}>
                                    <ul className={'tree'}>
                                        {categoryList.map((cat, i) => {
                                            return <SelectTest category={cat} key={i} usedTests={usedTests} isMultiple={false}
                                                               onSelectTest={(test) => onSelectSingleTest(test)}/>;
                                        })}
                                    </ul>
                                </div>
                            </ClickOutside>
                        </div>
                    </form>}
                    {isFetching && <div className="d-flex justify-content-center">
                        <p className="text-muted mt-3">Идет генерация опросного листа. <br/>Это может занять некоторое
                            время...</p>
                    </div>}
                </div>

                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal"
                            onClick={() => onCancel()}>
                        Закрыть форму
                    </button>
                    <button type="button" className="btn btn-orange" onClick={() => generateQuestionare()}
                            disabled={isFetching}>
                        Генерировать опросный лист
                    </button>
                </div>
            </Modal>
        </>);
}

function SelectTest({category, usedTests, onSelectTest}) {
    const [isOpen, setIsOpen] = React.useState(false);

    return (<li>
        <button type={'button'} className={'tree-toggle-btn ' + (isOpen ? 'btn-minus' : 'btn-plus')}
                onClick={() => setIsOpen(!isOpen)}/>
        {category.name}
        <ul style={{display: isOpen ? 'block' : 'none'}} className={'tree'}>
            {category.testList.map((test, i) => {
                const isChecked = usedTests.findIndex(el => el === test.id) >= 0;
                return <li key={i}>
                    {/*<div className="form-check" style={{display: 'flex'}} key={i}>*/}
                        <button type={'button'} className={'dropdown-item select-test-btn'}
                                onClick={() => onSelectTest(test)}>
                        {test.name}
                        </button>
                    {/*</div>*/}
                </li>;
            })}
        </ul>
    </li>);
}


SelectTest.propTypes = {
    onSelectTest: PropTypes.func,
    category: PropTypes.object,
    usedTests: PropTypes.array
};

AdminGenerateUserTest.propTypes = {
    categoryList: PropTypes.array,
    onSuccess: PropTypes.func
};

export default AdminGenerateUserTest;