import React from 'react';
import PropTypes from 'prop-types';
import Modal from "../../components/Modal";
import svgPlus from '../../sources/img/plus.svg';
import {ReactSVG} from 'react-svg';
import svgTrash from '../../sources/img/trash.svg';
import {apiService} from "../../services/apiService";

function AddQuestionForm({test, onFinish, isEditOpen, isEditMode, editedQuestion}) {
    const [isOpen, setOpen] = React.useState(false);
    const [isSaveAnswerOptions, setSaveAnswerOptions] = React.useState(false);
    const [isFetching, setFetching] = React.useState(false);
    const [question, setQuestion] = React.useState({
        question: '',
        answerOptions: [{answer: '', value: 0}],
        parameters: {}
    });

    React.useEffect(() => {
        if (isEditMode) {
            setQuestion({...editedQuestion});
        }
    }, [editedQuestion]);

    // React.useEffect(() => {
    //     if(isEditMode) {
    //         setQuestion({...editedQuestion});
    //     }
    //     setOpen(isEditOpen);
    // },[isEditOpen]);

    const addQuestion = () => {
        if (question.question === '') {
            alert('Текст вопроса не может быть пустым! Введите вопрос.');
        } else if (question.answerOptions.length < 2) {
            alert('Добавьте хотя бы два варианта ответа!');
        } else {
            const q = {
                testId: test.id,
                question: question.question,
                answerOptions: JSON.stringify(question.answerOptions),
                parameters: JSON.stringify(question.parameters),
            };
            if (isEditMode) {
                q.id = question.id;
            }
            setFetching(true);
            apiService.addQuestion(q).then(
                response => {
                    if (response.status === 'SUCCESS') {
                        eraseQuestion();
                        if (isEditMode) {
                            onCancelAdd();
                        }
                    }
                }
            ).finally(() => {
                setFetching(false);
            })
        }
    };

    const onCancelAdd = () => {
        setOpen(false);
        onFinish(true);
    };

    const eraseQuestion = () => {
        setQuestion({
            question: '',
            answerOptions: isSaveAnswerOptions ? [...question.answerOptions] : [{answer: '', value: 0}],
            parameters: {}
        });
    };

    return (
        <>
            {!isEditMode &&
            <button type="button" className="btn btn-orange-bordered ml-2" onClick={() => setOpen(true)}>
                <ReactSVG src={svgPlus}/>
                Добавить вопрос
            </button>}
            {isEditMode && <button type="button" title="Редактировать вопрос" className="edit-btn"
                                   onClick={() => {
                                        setOpen(true);
                                   }}>
            </button>
            }
            <Modal isClosable={false} title={isEditMode ? 'Редактирование вопроса' : 'Добавление вопроса'}
                   isOpen={isOpen}
                   isCustomBody={false} footer={false}>
                <form>
                    {question && <div className="modal-body">

                        <div className="form-group">
                            <label htmlFor="question">Введите вопрос</label>
                            <textarea rows={'6'} className="form-control"
                                      onChange={(e) => setQuestion({...question, question: e.target.value})}
                                      value={question.question}/>
                        </div>
                        {/*дополнительный параметр для вопроса МИЛ*/}
                        {test.methodology === 'kettell-16' &&
                        <div className="form-group">
                            <label htmlFor="question">Шкала</label>
                            <input type="text" className="form-control" value={question.parameters.scale}
                                   onChange={e => {
                                       let qParams = question.parameters;
                                       qParams['scale'] = e.target.value;
                                       setQuestion({...question, parameters: qParams});
                                   }}
                                   placeholder="Введите шкалу (A,B,C ... Q3, Q4)"
                            />
                        </div>
                        }
                        <div className="d-flex justify-content-between mt-5">
                            <label>Варианты ответа</label>
                            <button type="button" className="btn btn-success"
                                    onClick={() => {
                                        setQuestion({
                                            ...question,
                                            answerOptions: [...question.answerOptions, {answer: '', value: 0}]
                                        });
                                    }}>
                                Добавить вариант ответа
                            </button>
                        </div>
                        <div id="answerOptionsFields" className="mb-5">
                            {question.answerOptions.map((ao, i) => {
                                return <div className="row mt-2" key={i}>
                                    <div className="col-6 pr-0">
                                        <input type="text" className="form-control" value={ao.answer}
                                               onChange={e => {
                                                   let answerOptions = question.answerOptions;
                                                   answerOptions[i].answer = e.target.value;
                                                   setQuestion({...question, answerOptions: answerOptions});
                                               }}
                                               placeholder="Ответ"
                                        />
                                    </div>
                                    <div className="col-5">
                                        <input type="number" className="form-control" value={ao.value}
                                               onChange={e => {
                                                   let answerOptions = question.answerOptions;
                                                   answerOptions[i].value = e.target.value;
                                                   setQuestion({...question, answerOptions: answerOptions});
                                               }}
                                               placeholder="Количество баллов"/>
                                    </div>
                                    <div className="col-1 pl-0">
                                        <button type="button" className="icon-btn" key={'del-' + i}
                                                onClick={() => {
                                                    let answerOptions = question.answerOptions;
                                                    answerOptions.splice(i, 1);
                                                    setQuestion({...question, answerOptions: answerOptions});
                                                }}
                                                title="Удалить вариант ответа">
                                            <ReactSVG src={svgTrash}/>
                                        </button>
                                    </div>
                                </div>;
                            })}
                        </div>
                        {!isEditMode && <div className="form-check" style={{display: 'flex'}}>
                            <button type={'button'}
                                    className={'btn check-btn' + (isSaveAnswerOptions ? ' checked' : '')}
                                    onClick={() => setSaveAnswerOptions(!isSaveAnswerOptions)}/>
                            <label className="form-check-label">
                                Запомнить варианты ответа для следующего вопроса
                            </label>
                        </div>}
                    </div>}
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                onClick={() => onCancelAdd()}>
                            Закрыть форму
                        </button>
                        {!isEditMode && <button type="button" className="btn btn-orange" onClick={() => addQuestion()} disabled={isFetching}>
                            Добавить вопрос
                        </button>}
                        {isEditMode && <button type="button" className="btn btn-orange" onClick={() => addQuestion()} disabled={isFetching}>
                            Применить изменения
                        </button>}
                    </div>
                </form>
            </Modal>
        </>
    );
}

AddQuestionForm.propTypes = {
    test: PropTypes.object.isRequired,
    onFinish: PropTypes.func,
    isEditOpen: PropTypes.bool,
    isEditMode: PropTypes.bool,
    editedQuestion: PropTypes.object
};

AddQuestionForm.defaultProps = {
    isEditMode: false
};

export default AddQuestionForm;