import {apiService} from "./apiService";

export function authorizeUser(username, password) {
    let credentials = btoa(username + ':' + password);
    return apiService.checkCredentials(credentials, username).then(
        response => {
            if(response.status === 'SUCCESS'){
                sessionStorage.setItem('dso_user', JSON.stringify({authLine: credentials, role: response.response.authorities[0].role, un: username}));
            }
            return response;
        },error => {
            if(error.status === 401){
                return Promise.reject('Неправильное имя пользователя и(или) пароль!');
            }else{
                return Promise.reject('Ошибка на сервере. Попробуйте позже или обратитесь к системному администратору.');
            }
            return error;
        }
    );
}

export function getCurrentUser() {
    const dsoUser = sessionStorage.getItem('dso_user');
    if(dsoUser){
        return JSON.parse(dsoUser);
    }else{
        return null;
    }

}

export function isAdmin(){
    const user = getCurrentUser();
    if(user.role === 'ADMIN'){
        return true;
    }else{
        return false;
    }
}


export function logout() {
    sessionStorage.removeItem('dso_user');
}


export const loginService = {
    getCurrentUser,
    authorizeUser,
    isAdmin,
    // confirmEmail,
    logout
};

